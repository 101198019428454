.dashboard__title {
    text-align: center;
    font-weight: bolder;
}

.dashboard__text {
    text-align: center;
}

.dashboard__time {
    margin-top: 1rem;
    text-align: center;
}

.dashboard__qrcode {
    padding: 1rem;
    background-color: #fff;
}

.dashboard__qrcode svg {
    display: block;
    margin: 0 auto;
}

.recent_surveys {
    display: flex;
    margin-top: 0.5rem;
    background-color: white;
    align-items: center;
    padding: 1.5rem 1rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.recent_surveys.entry {
    font-weight: 400;
}

.recent_surveys > div {
    flex-basis: 25%;
}

.download {
    fill: #cc2020;
}

.continue {
    fill: #d3a045;
}

.remove-survey,
.continue-survey {
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
}