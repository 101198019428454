.bottomNav {
    display: flex;
    width: 100%;
    background-color: #333;
    align-items: flex-end;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0.25rem;
    padding-bottom: 1.5rem;
}

.bottomNav__link {
    color: #fff;
    padding: 0.75rem;
    flex-basis: 33.3%;
    text-decoration: none;
    font-size: 0.75rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 0.125rem;
    user-select: none;
}

.bottomNav__link svg,
.bottomNav__link img {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
    position: relative;
    fill: #fff;
}

.bottomNav__link.active, .bottomNav__link:hover, .bottomNav__link:focus {
    color: #4cc33f;
}

.bottomNav__link:hover svg,
.bottomNav__link:hover img,
.bottomNav__link:focus svg,
.bottomNav__link:focus img {
    fill: #4cc33f;
}

.bottomNav__link.active svg,
.bottomNav__link.active img {
    fill: #4cc33f;
}

