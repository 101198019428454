.photoContainer {
    width: 20%;
}

.previewContainer {
    border: 1px solid #999;
    border-radius: 5px;
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    padding: 5px;
    position: relative;
}

.preview {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.removePhoto {
    width: 18px;
    height: 18px;
    background-color: #CD2121;
    color: #fff;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -9px;
    right: -9px;
    padding: 5px;
    border: none;
    cursor: pointer;
    z-index: 10;
}

.uploadedPhoto {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: -9px;
    right: -9px;
    z-index: 10;
}

.uploadedPhoto,
.failedPhoto {
    color: #fff;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -9px;
    right: -9px;
    padding: 5px;
    border: none;
}

.uploadButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border: 0;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.removedBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
}

.removedBackground svg {
    font-size: 2rem;
}